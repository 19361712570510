.banner__wrapper {
  /* display: flex;
  flex-direction: row;
  align-content: space-around; */
  background-color: #46170d;
  color: white;
}

.banner__item2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.banner__item2VegIcon {
  height: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .banner__item1 {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .banner__item2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
