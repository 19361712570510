.logoImg {
  width: 150px;
}
.isvisible {
  visibility: hidden;
}

.tets {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.navv {
  width: 100%;
}
