@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 1rem; */
}

.product__img {
  /* height: 9.68rem;
  width: 9.68rem; */
  margin: 1rem;
}

.product__img:hover {
  cursor: pointer;
}
.product__name {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 12px;
  width: 75%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 36px;
}
