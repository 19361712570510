@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 10px 10px 0 10px; */
}

.item__image {
  height: 124px;
  width: 1524x;
  border-radius: 100%;
  /* margin-top: 2rem;
  padding: 0.3rem; */
  /* border: 1px red dotted; */
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  align-items: center;
}
.dropdown__div {
  z-index: -1;
  position: absolute;
  top: 60px;
  padding: 20px 30px 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0% 0% 10px 10px;
  box-shadow: 0px 2px 8px 0px #000000;
}

.fadeOut {
  animation: myAnim2 400ms ease 0s 1 reverse forwards;
}
.fadeIn {
  animation: myAnim 300ms ease 0s 1 normal forwards;
}

.item__name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding: 30px 0 40px;

  color: #606060;
}
