.whatsappChat {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.whatsappChat img {
  height: 60px;
  width: 60px;
}
/* .whatsappChat img:hover {
  height: 60px;
  width: 60px;
} */

.whatsappChat:hover {
  cursor: pointer;
}
