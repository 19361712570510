.products__heading {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  margin-top: 2rem;
}

.product__items {
  /* display: grid;
  text-align: center;
  width: 80%;
  justify-content: center;
  align-items: flex-start;
  padding-top: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  grid-auto-rows: 1fr; */
}

.product {
  text-align: center;
}

.products__flex {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.backButton {
  padding: 7px 0;
  font-weight: bold;
  font-size: medium;
}
.backButton:hover {
  padding: 7px 0;
  cursor: pointer;
}
.clicked {
  visibility: visible;
}
.notClicked {
  visibility: hidden;
}
.scale-in-center {
  -webkit-animation: scale-in-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.scale-in-center2 {
  -webkit-animation: scale-in-center2 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center2 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-8 18:8:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes scale-in-center2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
