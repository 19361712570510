@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.newsletter {
  position: absolute;
}

.cont {
  font-family: "Bebas Neue", cursive;
  color: "black";
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.hashtag {
  font-family: "Rubik", sans-serif;
  font-size: "16px";
  font-weight: "700";
  font-style: italic;
}
.shopNow {
  width: 106px;
  height: 49px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  color: black;
  margin-top: 38px;
}
.choc {
  font-size: 92px;
  padding: 0;
  margin: 0;
  line-height: 0.8;
}

@media only screen and (max-width: 418px) {
  .choc {
    font-size: 72px;
  }
}
