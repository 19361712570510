.carousel-indicators [data-bs-target] {
  width: 10px !important;
  height: 10px !important;
  border: 1px solid #fff !important;
  border-radius: 10px;
  opacity: 1 !important;

  background-color: transparent !important;
  /* z-index: -1; */
}
.carousel-indicators {
  bottom: 15% !important;
  right: 1% !important;
  left: auto !important;
  margin: 0 !important;
}

.carousel-indicators .active {
  background-color: #fff !important;
  opacity: 1 !important;
}

.carousel-control-next {
  display: none !important;
}

.carousel-control-prev {
  display: none !important;
}

/* .carouselStryles {
  z-index: -2;
} */
img.slide {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 100vh;
}
.imgg {
  height: 589px;
  width: 1280px;
  object-fit: cover;
}
