@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.formC {
  font-family: "Rubik", sans-serif;
  display: block;
  position: absolute;
  z-index: 10;
}

@media screen and (max-width: 576px) {
  .formC {
    display: none;
  }
}
