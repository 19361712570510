.kesarBanner {
  padding: 10px 0 54px 0;

  display: flex;
  flex-direction: row;
}

.kesarBanner > img {
  flex: 9;
  width: 100%;
}
.kesarBanner__left {
  flex: 3;
  background-color: #fedb0f;
  width: auto;
}

@media only screen and (max-width: 992px) {
  .kesarBanner {
    padding: 50px 0;

    display: flex;
    flex-direction: row;
  }
  .kesarBanner__left {
    flex: 0;
    visibility: hidden;
  }
  .kesarBanner > img {
    flex: 12;
    width: 100%;

    /* object-fit: contain; */
  }
}
