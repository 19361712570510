@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.footer {
  display: flex;
  flex-direction: row;
}

.footerBg {
  padding: 2rem 0;
  background-color: #f5f5f5;
}

.footer__company {
  flex: 5;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  padding: 1rem 1rem 1rem 0;
}

.footer__company p {
  padding-right: 2rem;
  padding-bottom: 1.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #8d8a8a;
}
.footer__policies {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 0;
}

.footer__policies a {
  text-decoration: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #8d8a8a;
  padding: 0.5rem 0;
}

.footer__shop {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 0;
}

.footer__shop a {
  text-decoration: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #8d8a8a;
  padding: 0.5rem 0;
}

.footer__newsletter {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 0;
}

.footer__newsletter p {
  text-decoration: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #8d8a8a;
  padding: 0.5rem 0;
}

.footer__textField {
  border-radius: 0;
}

@media only screen and (max-width: 777px) {
  .footer {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    background-color: #f5f5f5;
  }
  .footer__policies {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1rem 0;
  }
  .footer__shop {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1rem 0;
  }
  .footer__newsletter {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1rem 0;
  }
}
